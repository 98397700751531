.edgeBrowser{
  background: rgb(231, 234, 236);
}

.vscodeWn{
  background: rgb(37, 37, 38);
}

.wnstore{
  background: rgb(241, 243, 245);
}

.wnterm{
  background: rgb(16, 16, 16);
}

.notepad{
  background: #fefefe;
}

.calcApp{
  background: #eee;
}

.msfiles, .whiteBoard{
  background: #fefefe;
}
